export enum PaymentMethod {
  PayWithNewPaymentMethod = 'Pay With New Payment Method',
  PayWithExistingPaymentMethod = 'Pay With Existing Payment Method',
}

export enum ExistingPaymentMethods {
  PayWithExistingPaymentMethod = 'Existing Payment Method',
  PayWithCard = 'New Credit Card',
  PayWithBank = 'New Bank Account',
  PayWithCash = 'Cash',
  PayWithCheck = 'Check',
  PayWithOther = 'Other',
}

export enum ExistingPaymentMethodForInvoice {
  PayWithExistingPaymentMethod = 'Existing Payment Method',
  PayWithCard = 'New Credit Card',
  PayWithBank = 'New Bank Account',
  PayWithCash = 'Cash',
  PayWithCheck = 'Check',
  PayWithOther = 'Other',
}

export enum PaymentType {
  PayWithBank = 'Pay With Bank Account',
  PayWithCard = 'Pay With Credit/Debit Card',
}
