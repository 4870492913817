import { white } from 'styles/colors';

/**
 * @description Get color on basis of theme
 * @param isLightMode is light theme or not
 * @param lightThemeColor color for light theme
 * @param darkThemeColor color for dark theme, if not provided then by-default is white
 * @returns color as a string
 */
const getThemeBasedCustomColor = (
  isLightMode: boolean,
  lightThemeColor: string,
  darkThemeColor = white,
) => {
  return isLightMode ? lightThemeColor : darkThemeColor;
};

export default getThemeBasedCustomColor;
