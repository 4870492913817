// black
export const black1 = '#000000';
export const black2 = '#1e2940';

// greens
export const green1 = '#0F995F';
export const green2 = '#11AA5B';
export const green3 = '#5CCC9D';
export const green4 = '#8FDBBB';
export const green5 = '#CEEBDF';
export const green6 = '#E6F7F0';
export const green7 = '#06391E';
export const green8 = '#0E8E4C';
export const green9 = '#39BB78';
export const green10 = '#EEFCF5';
export const green11 = '#8BD9B0';
export const green12 = '#f1fce9';
export const green13 = '#02810f';
export const green14 = '#EEFCF5';
export const green15 = '#0C8E4C';
export const green16 = '#fafdfb';
export const green17 = '#13aa5b';
export const green18 = '#00810F';
export const green19 = '#F1FCE9';

// blues
export const blue1 = '#3369D4';
export const blue2 = '#5185EC';
export const blue3 = '#7CA8FF';
export const blue4 = '#B2CCFF';
export const blue5 = '#D0E0FF';
export const blue6 = '#E8EFFC';
export const blue7 = '#e6f8f9';
export const blue8 = '#007e8a';
export const blue9 = '#E8EFFD';
export const blue10 = '#3369D4';

// yellows
export const yellow1 = '#D69513';
export const yellow2 = '#E5AC39';
export const yellow3 = '#F0C267';
export const yellow4 = '#F2D69D';
export const yellow5 = '#FAE5BC';
export const yellow6 = '#FCF2DC';
export const yellow7 = '#FFBF3D';
export const yellow8 = '#fff4df';
export const yellow9 = '#c4b40b';
export const yellow10 = '#FFF4DF';
export const yellow11 = '#CC7B01';

// oranges
export const orange1 = '#FC5603';
export const orange2 = '#FFE6D9';

// reds
export const red1 = '#C4254D';
export const red2 = '#DF3C64';
export const red3 = '#FA6489';
export const red4 = '#F09CB1';
export const red5 = '#F2DAE0';
export const red6 = '#FFF2F5';
export const red7 = '#DF3B6414';
export const red8 = '#DF3B64';
export const red9 = '#fff2f5';
export const red10 = '#fb6489';
export const red11 = '#cc7b01';
export const red12 = '#ffeeef';
export const red13 = '#ea3700';
export const red14 = '#ffeeef';
export const red15 = '#ea3700';

// grays
export const gray1 = '#1C1E21';
export const gray2 = '#242629';
export const gray3 = '#3D3F45';
export const gray4 = '#5E6470';
export const gray5 = '#818896';
export const gray6 = '#B7BCC7';
export const gray7 = '#DADDE5';
export const gray8 = '#F0F2F5';
export const gray9 = '#F7F8FA';
export const gray10 = '#1B273E';
export const gray11 = '#5B677E';
export const gray12 = '#0F1B32';
export const gray13 = '#CBD1DB';
export const gray14 = '#E8ECF2';
export const gray15 = '#F6F7FA';
export const gray16 = '#34415A';
export const gray17 = '#818B9D';
export const gray18 = '#f6f7fa';
export const gray19 = '#0e1b32';
export const gray20 = '#0F1B32';

// teal
export const teal1 = '#00565A';
export const teal2 = '#007E8A';
export const teal3 = '#00BECC';
export const teal4 = '#A4E8EC';
export const teal5 = '#E3FDFF';
export const teal6 = '#26344E';

// purple
export const purple1 = '#4B17A1';
export const purple2 = '#7E23F1';
export const purple3 = '#AF81F8';
export const purple4 = '#D8C0FF';
export const purple5 = '#F0E6FD';
export const purple6 = '#f1e6fd';
export const purple7 = '#4b17a1';

// magenta
export const magenta1 = '#7E1F80';
export const magenta2 = '#B600C7';
export const magenta3 = '#FD83FF';
export const magenta4 = '#FEBBFF';
export const magenta5 = '#FFE3FF';

//yellow

export const background = gray9;
export const primaryBlue = blue2;
export const primaryGreen = green2;
export const primaryRed = red2;
export const primaryYellow = yellow2;
export const topBarDark = '#121314';
export const white = '#FFF';
