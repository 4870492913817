export enum ValidationMessage {
  TUITION_AMOUNT_REQUIRED = 'Tuition amount is required',
  INSTALLMENT_REQUIRED = 'At least one installment is required',
  INSTALLMENT_AMOUNT_MATCH = 'Installment amount exceeds plan amount',
  INSTALLMENT_GREATER_THAN_ONE = 'Installment amount must be greater than $1.00',
  PAYMENT_SCHEDULE_NOT_ADD_UP = 'Installment amount should add up to the total Tuition Amount',
  PAYMENT_SCHEDULE_CREATED = 'Payment schedule created successfully',
  PAYMENT_PLAN_CANCEL_SUCCESS = 'Payment schedule cancelled',
  PAYMENT_PLAN_CANCEL_FAILURE = 'Failed to cancel payment plan',
  SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later',
  NAME_ON_ACCOUNT_REQUIRED = 'Name on account is required',
  NAME_ON_CARD_REQUIRED = 'Name on card is required',
  ROUTING_NUMBER_REQUIRED = 'Routing number is required',
  ACCOUNT_NUMBER_REQUIRED = 'Account number is required',
  REFERENCE_NUMBER_REQUIRED = 'Reference number is required',
  CARD_NUMBER_REQUIRED = 'Card number is required',
  CVV_REQUIRED = 'CVV is required',
  EXPIRATION_DATE_REQUIRED = 'Expiration date is required',
  PAYMENT_METHOD_ADDED = 'Payment method added successfully',
  SET_DEFAULT_METHOD_SUCCESS = 'Default payment method set successfully',
  PAYMENT_METHOD_DELETED = 'Payment method deleted successfully',
  AUTO_PAY_SET_TRUE = 'Autopay is set successfully',
  AUTO_PAY_SET_FALSE = 'Autopay is turned off successfully',
  AUTO_PAY_METHOD_UPDATED = 'Autopay method updated successfully',
  PAYMENT_PROCESS_TAKES_LONGER_TIME = 'Your payment is taking longer than expected. We will notify you once the payment is processed.',
  PAYMENT_FAILED = 'Payment failed. Please try again.',
  NO_PAYMENT_SCHEDULE = 'No Payment Method, Please add new.',
  PAYMENT_SCHEDULE_UPDATED_SUCCESSFULLY = 'Payment schedule updated successfully',
  REPORT_GENERATE_SUCCESSFULLY = 'The report is being generated. The report will be sent to your email.',
  REFUND_TRANSACTION = 'Transaction refunded successfully.',
  OWNERSHIP_PERCENTAGE_ERROR = 'The total ownership percentage must be 100 percent or less',
  WEBSITE_LENGTH_VALIDATION_ERROR = 'Website cannot be more than 64 characters',
  PHONE_NUMBER_VALIDATION_ERROR = 'Phone number must be 10 digits',
  NO_SCHOOL_SELECTED = 'Please select a school to generate the report',
  PAYMENT_NOT_PROCESSED = "Payment couldn't be processed. Please try again.",
  BILLING_CONTACT_CREATED = 'Billing contact created successfully!',
  BILLING_CONTACT_UPDATED = 'Billing contact updated successfully!',
  BILLING_CONTACT_DELETED = 'Billing contact deleted successfully!',
  EXTRA_ITEM_GREATER_THAN_ONE = 'Payment items amount must be greater than $1.00',
  DELETE_TEMPLATE_SUCCESS_MESSAGE = 'Template deleted successfully',
  TEMPLATE_UPDATED = 'Template updated successfully',
  TEMPLATE_CREATED = 'Template created successfully',
  TEMPLATE_NAME_REQUIRED = 'Template name is required',
  SCHOOL_NOT_SELECTED = 'At least one school must be selected',
  SALES_TAX_RATE_GREATER_THAN_ZERO = 'Sales tax rate must be greater than $0.00',
  INSTALLMENT_DATE_ERROR = 'Please enter a valid date',
  PAYMENT_SCHEDULE_NAME_ERROR = 'Please enter a payment schedule name with at least 4 characters.',
  DOWN_PAYMENT_EXCEEDS_TUITION_AMOUNT = 'Down payment cannot be greater than {tuitionAmount}',
}

export enum TransactionStatus {
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'PAID',
}

export enum PaymentMethod {
  BANK = 'Bank',
  CARD = 'Card',
}

export enum TransactionListSortByKey {
  PaymentDate = 'paymentDate',
  Amount = 'totalAmount',
  Status = 'status',
}

export enum PaymentSchedule {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum PaymentScheduleListSortByKey {
  CREATED = 'created',
  TOTAL_TUITION = 'totalTuition',
  STATUS = 'status',
}

export enum Tabs {
  INVOICES = 'Invoices',
  TRANSACTIONS = 'Transactions',
}

export enum AccountSummaryStatus {
  CURRENT = 'CURRENT',
  LOA = 'LOA',
  MISSED = 'MISSED',
  OVERDUE = 'OVERDUE',
}

export enum StudentAccountTabs {
  DASHBOARD = 'Dashboard',
  MYPAYMENTS = 'My Payments',
}

export enum FinancePaymentsTabs {
  INVOICES = 'Invoices',
  TRANSACTIONS = 'Transactions',
  PAYMENT_SCHEDULES = 'Payment Schedules',
}

export enum IFieldType {
  ACH_TYPE = 'ach',
  CARD_TYPE = 'card',
  CVV_TYPE = 'cvv',
}

export enum StorageKey {
  TRANSACTION_LIST_PAGE_FILTERS = 'transactions_list_page_filters',
  PAYMENT_SCHEDULE_LIST_PAGE_FILTERS = 'payment_schedule_list_page_filters',
}

export enum PaymentStatus {
  ACTIVE = 'ACTIVE',
  CURRENT = 'CURRENT',
  OVERDUE = 'OVERDUE',
  MISSED = 'MISSED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum PaymentScheduleStatus {
  UPCOMING = 'UPCOMING',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PARTIAL = 'PARTIALLY_PAID',
  PARTIALLY_PAID = 'PARTIALLY PAID',
  UNPAID = 'UNPAID',
}

export enum ReportType {
  PDF = 'PDF',
  CSV = 'CSV',
}

export enum IFinanceFields {
  INVOICE = 'Invoice',
  PAYMENT_SCHEDULE = 'Payment Schedule',
  BROWSE_TEMPLATES = 'Browse Templates',
}

export enum PaymentTemplates {
  INVOICE = 'Invoices',
  PAYMENT_SCHEDULES = 'Payment Schedules',
}
